export default [
  {
    title: 'Tableau de bord',
    route: 'espace-agent.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Gestion des demandes',
    icon: 'ListIcon',
  },
  {
    title: 'Liste des Demandes',
    route: 'espace-agent.list-demandes',
    icon: 'FileTextIcon',
  },
]
